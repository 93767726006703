const environment =
  process.env.REACT_APP_ENVIRONMENT === "production"
    ? "prod"
    : process.env.REACT_APP_ENVIRONMENT === "staging"
    ? "staging"
    : "dev";

const config = require(`../config/config.${environment}`).default;

// ---------- MAIN NETWORKS ----------
// TODO: Add Abstract when the chain is live
const ETH_MAINNET = {
  chainId: "0x1",
  chainName: "Ethereum Mainnet",
  nativeCurrency: {
    name: "Ethereum",
    symbol: "ETH",
    decimals: 18,
  },
  rpcUrls: ["https://eth.llamarpc.com"],
  blockExplorerUrls: ["https://etherscan.io"],
};

export const BSC_MAINNET = {
  chainId: "0x38",
  chainName: "BNB Smart Chain Mainnet",
  nativeCurrency: {
    name: "BNB",
    symbol: "BNB",
    decimals: 18,
  },
  rpcUrls: ["https://bsc-dataseed.binance.org/"],
  blockExplorerUrls: ["https://bscscan.com"],
};

export const POLYGON_MAINNET = {
  chainId: "0x89",
  chainName: "Polygon Mainnet",
  nativeCurrency: {
    name: "MATIC",
    decimals: 18,
    symbol: "MATIC",
  },
  rpcUrls: ["https://polygon-rpc.com/"],
  blockExplorerUrls: ["https://polygonscan.com/"],
};

export const AVAX_MAINNET = {
  chainId: "0xa86a",
  chainName: "Avalance mainnet",
  nativeCurrency: {
    name: "Avalanche",
    symbol: "AVAX",
    decimals: 18,
  },
  rpcUrls: ["https://api.avax.network/ext/bc/C/rpc"],
  blockExplorerUrls: ["https://cchain.explorer.avax.network/"],
};

export const ARBITRUM_MAINNET = {
  chainId: "0xa4b1",
  chainName: "Arbitrum One Mainnet",
  nativeCurrency: {
    name: "Arbitrum",
    symbol: "ETH",
    decimals: 18,
  },
  rpcUrls: ["https://arb1.arbitrum.io/rpc"],
  blockExplorerUrls: ["https://arbiscan.io/"],
};

export const BASE_MAINNET = {
  chainId: "0x2105",
  chainName: "Base",
  nativeCurrency: {
    name: "Ethereum",
    decimals: 18,
    symbol: "ETH",
  },
  rpcUrls: ["https://mainnet.base.org"],
  blockExplorerUrls: ["https://basescan.org"],
};

export const MAINETNETWORKID = [
  {
    id: 56,
    suffix: BSC_MAINNET.chainId,
    name: "BSC",
    fullName: "binance",
    symbol: BSC_MAINNET.nativeCurrency.symbol,
  },
  {
    id: 1,
    suffix: ETH_MAINNET.chainId,
    name: "ETH",
    fullName: "ethereum",
    symbol: ETH_MAINNET.nativeCurrency.symbol,
  },
  {
    id: 137,
    suffix: POLYGON_MAINNET.chainId,
    name: "POLYGON",
    fullName: "polygon",
    symbol: POLYGON_MAINNET.nativeCurrency.symbol,
  },
  {
    id: 43114,
    suffix: AVAX_MAINNET.chainId,
    name: "AVAX",
    fullName: "avalanche",
    symbol: AVAX_MAINNET.nativeCurrency.symbol,
  },
  {
    id: 42161,
    suffix: ARBITRUM_MAINNET.chainId,
    name: "ARBITRUM",
    fullName: "arbitrum",
    symbol: ARBITRUM_MAINNET.nativeCurrency.symbol,
  },
  {
    id: 8453,
    suffix: BASE_MAINNET.chainId,
    name: "BASE",
    fullName: "base",
    symbol: BASE_MAINNET.nativeCurrency.symbol,
  },
];

export const MAINNETCHAINID = [
  ETH_MAINNET.chainId,
  BSC_MAINNET.chainId,
  POLYGON_MAINNET.chainId,
  AVAX_MAINNET.chainId,
  ARBITRUM_MAINNET.chainId,
  BASE_MAINNET.chainId,
];

export const MAINNET_NETWORKS = {
  [ETH_MAINNET.chainId]: ETH_MAINNET,
  [BSC_MAINNET.chainId]: BSC_MAINNET,
  [POLYGON_MAINNET.chainId]: POLYGON_MAINNET,
  [AVAX_MAINNET.chainId]: AVAX_MAINNET,
  [ARBITRUM_MAINNET.chainId]: ARBITRUM_MAINNET,
  [BASE_MAINNET.chainId]: BASE_MAINNET,
};

// ---------- TEST NETWORKS ----------
export const ETH_TESTNET = {
  chainId: "0xaa36a7",
  chainName: "Sepolia Testnet",
  nativeCurrency: {
    name: "Ethereum",
    symbol: "ETH",
    decimals: 18,
  },
  rpcUrls: ["https://rpc2.sepolia.org"],
  blockExplorerUrls: ["https://sepolia.etherscan.io/"],
};

export const BSC_TESTNET = {
  chainId: "0x61",
  chainName: "Binance Smart Chain Testnet",
  nativeCurrency: {
    name: "BNB",
    symbol: "BNB",
    decimals: 18,
  },
  rpcUrls: ["https://bsc-testnet.drpc.org/"],
  blockExplorerUrls: ["https://testnet.bscscan.com"],
};

export const POLYGON_TESTNET = {
  chainId: "0x13882",
  chainName: "Polygon Amoy Testnet",
  nativeCurrency: {
    name: "Polygon",
    symbol: "POL",
    decimals: 18,
  },
  rpcUrls: ["https://rpc-amoy.polygon.technology"],
  blockExplorerUrls: ["https://amoy.polygonscan.com"],
};

export const AVAX_TESTNET = {
  chainId: "0xa869",
  chainName: "Avalance Testnet",
  nativeCurrency: {
    name: "Avalanche",
    symbol: "AVAX",
    decimals: 18,
  },
  rpcUrls: ["https://api.avax-test.network/ext/bc/C/rpc"],
  blockExplorerUrls: ["https://cchain.explorer.avax-test.network/"],
};

export const ARBITRUM_TESTNET = {
  chainId: "0x66eee",
  chainName: "Arbitrum Sepolia Testnet",
  nativeCurrency: {
    name: "Ether",
    decimals: 18,
    symbol: "ETH",
  },
  rpcUrls: ["https://sepolia-rollup.arbitrum.io/rpc"],
  blockExplorerUrls: ["https://sepolia-explorer.arbitrum.io"],
};

export const BASE_TESTNET = {
  chainId: "0x14a34",
  chainName: "Base Sepolia Testnet",
  nativeCurrency: {
    name: "Ethereum",
    symbol: "ETH",
    decimals: 18,
  },
  rpcUrls: ["https://sepolia.base.org"],
  blockExplorerUrls: ["https://sepolia.basescan.org"],
};

export const ABSTRACT_TESTNET = {
  chainId: "0x2b74",
  chainName: "Abstract Testnet",
  nativeCurrency: {
    name: "Ethereum",
    symbol: "ETH",
    decimals: 18,
  },
  rpcUrls: ["https://api.testnet.abs.xyz"],
  blockExplorerUrls: ["https://explorer.testnet.abs.xyz"],
};

export const TESTNETNETWORKID = [
  {
    id: 4,
    suffix: ETH_TESTNET.chainId,
    name: "ETH",
    fullName: "ethereum",
    symbol: ETH_TESTNET.nativeCurrency.symbol,
  },
  {
    id: 97,
    suffix: BSC_TESTNET.chainId,
    name: "BSC",
    fullName: "binance",
    symbol: BSC_TESTNET.nativeCurrency.symbol,
  },
  {
    id: 80002,
    suffix: POLYGON_TESTNET.chainId,
    name: "POLYGON",
    fullName: "polygon",
    symbol: POLYGON_TESTNET.nativeCurrency.symbol,
  },
  {
    id: 43114,
    suffix: AVAX_TESTNET.chainId,
    name: "AVAX",
    fullName: "avalanche",
    symbol: AVAX_TESTNET.nativeCurrency.symbol,
  },
  {
    id: 421614,
    suffix: ARBITRUM_TESTNET.chainId,
    name: "ARBITRUM",
    fullName: "arbitrum",
    symbol: ARBITRUM_TESTNET.nativeCurrency.symbol,
  },
  {
    id: 84532,
    suffix: BASE_TESTNET.chainId,
    name: "BASE",
    fullName: "base",
    symbol: BASE_TESTNET.nativeCurrency.symbol,
  },
  {
    id: 11124,
    suffix: ABSTRACT_TESTNET.chainId,
    name: "ABSTRACT",
    fullName: "abstract",
    symbol: ABSTRACT_TESTNET.nativeCurrency.symbol,
  },
];

export const TESTNETCHAINID = [
  ETH_TESTNET.chainId,
  BSC_TESTNET.chainId,
  POLYGON_TESTNET.chainId,
  AVAX_TESTNET.chainId,
  ARBITRUM_TESTNET.chainId,
  BASE_TESTNET.chainId,
  ABSTRACT_TESTNET.chainId,
];

export const TESTNET_NETWORKS = {
  [ETH_TESTNET.chainId]: ETH_TESTNET,
  [BSC_TESTNET.chainId]: BSC_TESTNET,
  [POLYGON_TESTNET.chainId]: POLYGON_TESTNET,
  [AVAX_TESTNET.chainId]: AVAX_TESTNET,
  [ARBITRUM_TESTNET.chainId]: ARBITRUM_TESTNET,
  [BASE_TESTNET.chainId]: BASE_TESTNET,
  [ABSTRACT_TESTNET.chainId]: ABSTRACT_TESTNET,
};

export const NETWORK_LIST =
  config.networkType === "mainnet" ? MAINNET_NETWORKS : TESTNET_NETWORKS;
export const CHAIN_ID_LIST =
  config.networkType === "mainnet" ? MAINNETCHAINID : TESTNETCHAINID;
export const NETWORK_DATA =
  config.networkType === "mainnet" ? MAINETNETWORKID : TESTNETNETWORKID;
